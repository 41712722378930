<template>
  <div class="del-wrap">
    <el-dialog title="审核"
               :visible="checkDialogVisible"
               :close-on-press-escape="false"
               :show-close="false"
               width="500px"
               top="0"
               @close="closeModal"
               center>
      <div class="check-wrap">
        <span>分成比：</span>
        <el-select style="width:80%;margin-left:10px"
                   v-model="separate_ratio"
                   placeholder="请选择">
          <el-option v-for="item in acceptRatioList"
                     :key="item.key"
                     :label="item.title"
                     :value="item.value">
          </el-option>
        </el-select>
        <p>确认介绍该经纪人成为中能魔力线上经纪人（您的介绍费将以分成形式体现，详见《平台使用手册》）</p>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button class="refuse"
                   @click="submitCheck(2)">拒绝</el-button>
        <el-button class="wait-check"
                   @click="submitCheck(0)">待审核</el-button>
        <el-button type="primary"
                   @click="submitCheck(1)"
                   style="box-shadow:none;">通过</el-button>

      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    checkDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      separate_ratio: 0,
      acceptRatioList: []
    }
  },
  created () {
    this._initState()
  },
  methods: {
    async _initState () {
      // 请求分成比
      const {
        status_code: acceptRatioList_status_code,
        message: acceptRatioList,
      } = await this.$api.acceptRatioListApi();
      if (acceptRatioList_status_code == 200) {
        this.acceptRatioList = acceptRatioList;
      }
    },
    submitCheck (status) {
      this.$emit('submitCheck', { status, separate_ratio: this.separate_ratio })
    },
    closeModal(){
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.del-wrap /deep/ button.el-button.el-button--default {
  width: 85px;
  height: 32px;
}
.check-wrap {
  width: 357px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  p {
    margin-top: 20px;
    padding: 10px;
    background: rgba(80, 157, 237, 0.1);
    font-size: 14px;
    color: #60c3f7;
    line-height: 20px;
  }
}
.wait-check {
  background: #60c3f7;
  color: #fff;
}
.refuse {
  color: #fff;
  background: #fab000;
}
.del-wrap /deep/.el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
</style>